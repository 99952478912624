exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-access-js": () => import("./../../../src/pages/access.js" /* webpackChunkName: "component---src-pages-access-js" */),
  "component---src-pages-action-js": () => import("./../../../src/pages/action.js" /* webpackChunkName: "component---src-pages-action-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-clients-js": () => import("./../../../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-confirm-js": () => import("./../../../src/pages/confirm.js" /* webpackChunkName: "component---src-pages-confirm-js" */),
  "component---src-pages-docs-js": () => import("./../../../src/pages/docs.js" /* webpackChunkName: "component---src-pages-docs-js" */),
  "component---src-pages-gateway-js": () => import("./../../../src/pages/gateway.js" /* webpackChunkName: "component---src-pages-gateway-js" */),
  "component---src-pages-heartbeat-js": () => import("./../../../src/pages/heartbeat.js" /* webpackChunkName: "component---src-pages-heartbeat-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legals-js": () => import("./../../../src/pages/legals.js" /* webpackChunkName: "component---src-pages-legals-js" */),
  "component---src-pages-life-js": () => import("./../../../src/pages/life.js" /* webpackChunkName: "component---src-pages-life-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-logout-js": () => import("./../../../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-organisation-js": () => import("./../../../src/pages/organisation.js" /* webpackChunkName: "component---src-pages-organisation-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-psu-js": () => import("./../../../src/pages/psu.js" /* webpackChunkName: "component---src-pages-psu-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-restricted-js": () => import("./../../../src/pages/restricted.js" /* webpackChunkName: "component---src-pages-restricted-js" */),
  "component---src-pages-return-js": () => import("./../../../src/pages/return.js" /* webpackChunkName: "component---src-pages-return-js" */),
  "component---src-pages-return-tell-js": () => import("./../../../src/pages/return_tell.js" /* webpackChunkName: "component---src-pages-return-tell-js" */),
  "component---src-pages-system-js": () => import("./../../../src/pages/system.js" /* webpackChunkName: "component---src-pages-system-js" */),
  "component---src-pages-welcome-js": () => import("./../../../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */)
}

