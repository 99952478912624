import React from 'react';

export const isBrowser = () => typeof window !== 'undefined';
export const GlobalStateContext = React.createContext();
export const GlobalDispatchContext = React.createContext();

export const getDarkModeSetting = () => {
	let mode = '';
	isBrowser() && window.localStorage.getItem('theme_mode')
		? (mode = window.localStorage.getItem('theme_mode'))
		: (mode = 'light');

	return mode;
};

const setDarkModeSetting = (mode) => {
	window.localStorage.setItem('theme_mode', mode);
};

const initialState = {
	theme: getDarkModeSetting(),
	toast: false,
};

function reducer(state, action) {
	switch (action.type) {
		case 'TOGGLE_THEME': {
			const new_mode = state.theme === 'light' ? 'dark' : 'light';
			setDarkModeSetting(new_mode);
			return {
				...state,
				theme: new_mode,
			};
		}
		case 'TRIGGER_TOAST': {
			const toast_mode = state.toast === true ? false : true;
			return {
				...state,
				toast: toast_mode,
			};
		}
		default:
			throw new Error('Bad Action Type');
	}
}

const GlobalContextProvider = ({ children }) => {
	const [state, dispatch] = React.useReducer(reducer, initialState);
	return (
		<GlobalStateContext.Provider value={state}>
			<GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
		</GlobalStateContext.Provider>
	);
};

export default GlobalContextProvider;
