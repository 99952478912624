import './src/assets/css/bootstrap.min.css';
import './src/assets/css/animate.min.css';
import './src/assets/css/boxicons.min.css';
import './src/assets/css/flaticon.css';
// import './node_modules/react-modal-video/css/modal-video.min.css';
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-image-lightbox/style.css';
import './src/assets/css/style.css';
import './src/assets/css/tailwind.css';
import './src/assets/css/theme_styles.css';
import './src/assets/css/responsive.css';
import './src/assets/css/cropper.css';
import './src/assets/css/Sidebar.css';
import './src/assets/css/markdown.css';
import './node_modules/css-toggle-switch/dist/toggle-switch.css';

// Brand Styling Imports
import './src/assets/brand/axs-afs/css/brand.css';

import React from 'react';

import GlobalContextProvider from './src/state/GlobalContextProvider';
// all styles here
// import "./node_modules/bootstrap/dist/css/bootstrap.css"

export const wrapRootElement = ({ element }) => <GlobalContextProvider>{element}</GlobalContextProvider>;
